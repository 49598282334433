@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  body {
    font-family: 'Dosis', sans-serif;
  }

  .page-layout {
    @apply h-auto p-2 md:pb-6 pt-28 md:pt-24 dark:bg-opacity-80 transform duration-500;
  }

  .skill-logo {
    @apply transition transform hover:-translate-y-1 hover:scale-110 duration-700 ease-in-out h-16 w-16 sm:h-20 sm:w-20 md:h-24 md:w-24 rounded-full;
  }

  .sticky-header {
    @apply text-gray-300 text-2xl text-center p-3 bg-blue-500 dark:bg-blue-900 transition duration-500;
  }

  .mobile-lang-text-style {
    @apply text-gray-900 dark:text-gray-300 text-2xl font-bold transition transform duration-500 ease-in-out;
  }

  .pc-lang-text-style {
    @apply text-gray-900 dark:text-gray-300 text-2xl font-bold transition transform hover:-translate-y-1 hover:scale-110 hover:text-blue-300 duration-500 ease-in-out;
  }

  .email-input {
    @apply bg-opacity-10 bg-white rounded-md h-20 w-full sm:w-3/4 lg:w-3/4 xl:w-4/6 2xl:w-1/2 p-5 text-gray-300 text-lg md:text-2xl block border border-transparent focus:outline-none focus:ring-1 focus:ring-gray-200 focus:border-transparent shadow-sm border-gray-100 placeholder-gray-200;
  }
  
  /* mobile view */
  .link-menu {
    @apply absolute flex flex-row items-center top-14 left-0 h-9 w-full pl-1.5 cursor-pointer;
  }

  .mobile-link-group {
    @apply flex items-center justify-center xs:pl-3 xs:justify-center gap-2 md:gap-6 w-full;
  }
  
  .mobile-link {
    @apply text-gray-300 text-center text-base sm:text-lg italic w-1/4 transform transition duration-500 tracking-wide md:tracking-widest;
  }
}