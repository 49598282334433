
a[education-tool-tip-msg] {
  position: relative;
  text-decoration: underline;
}

a[education-tool-tip-msg]::before {
    content: attr(education-tool-tip-msg);
    position: absolute;
    width: max-content;
    color: darkgrey;
    background-color: black;
    font-size: 10px;
    padding: 0px 10px 0px 10px;
    border-radius: 5px;
    opacity: 0.5;
    bottom: 0%;
    left: 110px;
}

@media (min-width: 768px) {
  a[education-tool-tip-msg]::before {
    font-size: 12px;
    opacity: 0.7;
    left: -20px;
    transform: scale(0);
    transition-duration: 250ms;
  }

  a[education-tool-tip-msg]:hover::before {
    transform: scale(1);
    left: 0px;
    bottom: -60%;
    transition: ease-out;
    transition-duration: 250ms;
  }
}

@media (max-width: 768px) {
  a[education-tool-tip-msg]::before {
    visibility: hidden;
  }
}
