.icon-container {
  position: absolute;
  top: 0%;
  padding-top: 3px;
  display: flex;
  align-items: center;
  color: rgba(209, 213, 219);
  transition: ease-out;
  transition-duration: 500ms;
  height: 34px;
  width: 34px;
  border-left: 1px;
  border-right-color: transparent;
  cursor: pointer;
}

.icon-container:hover {
  padding: 5px 5px 5px 5px;
  border-left-width: 1px;
  border-right-color: rgba(209, 213, 219);
}


.header-style {
  @apply bg-gray-600 bg-opacity-80 dark:bg-gray-900 dark:bg-opacity-80 transform duration-500 rounded-3xl shadow-2xl border border-gray-700 ;
}

.web-link-menu {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  top: 100px;
  border-radius: 16px;
  left: 0px;
  height: 40px;
  width: 40px;
  padding-left: 7px;
}

.web-link-menu:hover {
  width: max-content;
  height: max-content;
  border-radius: 16px;
  padding-right: 20px;
}

.pc-link-group {
  display: flex;
  flex-direction: column;
  gap: 2px;
  align-items: left;
  height: 24px;
  width: 0px;
  cursor: pointer;
  transition: ease-out;
  transition-duration: 500ms;
}

.web-link-menu:hover .pc-link-group {
  gap: 8px;
  width: fit-content;
  height: fit-content;
  padding: 20px 20px 20px 30px;
  transform: scaleX(1);
}

.link {
  font-size: 16px;
  line-height: 20px;
  color: rgba(209, 213, 219);
  font-style: italic;
  height: 20px;
  width: max-content;
  transform: scale(0);
  translate: -50px;
  cursor: pointer;
}

.link:hover {
  text-decoration: underline;
}

.web-link-menu:hover .link {
  transform: scale(1);
  translate: 0px;
  transition-duration: 500ms;
}

.menu-icon {
  position: absolute;
  transform: scale(1);
  transition: ease-out;
  transition-duration: 500ms;
  height: 23px;
  width: 23px;
}

.web-link-menu:hover .menu-icon {
  transform: scale(0) rotate(720deg);
  transition-duration: 150ms;
  opacity: 0;
}

.x-icon {
  position: absolute;
  transform: scale(0) rotate(-720deg);
  transition: ease-out;
  transition-duration: 150ms;
  height: 23px;
  width: 23px;
  
}

.web-link-menu:hover .x-icon {
  transform: scale(1);
  transition-duration: 500ms;
}